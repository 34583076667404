import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles((theme) => ({
  button: {
    display: 'block',
    marginTop: theme.spacing(1),
  },
  formControl: {
    width: "100px",
    marginTop: "1px"
  },

  SelectStyle: {
    height: "30px",
    background: "white",
  },"&:focus":{
    borderColor: "black"
  }
}));

export default function SelectOption(props) {
  const classes = useStyles();

  const handleChange = (event) => {
    props.onchangeFechaOption(event);
  };

  return (
    <>
      <FormControl  className={classes.formControl}>
        <Select
          variant="outlined"
          className={classes.SelectStyle}
          labelId="demo-controlled-open-select-label"
          id="demo-controlled-open-select"
          value={props.fechaOption}
          onChange={handleChange}
        >
          <MenuItem value={'day'}>Días</MenuItem>
          <MenuItem value={'semana'}>Semanas</MenuItem>
          <MenuItem value={'month'}>Meses</MenuItem>
        </Select>
      </FormControl>
    </>
  );
}
