import { useState, useEffect } from 'react';
import firebase from 'firebase';

export default function useFirebase() {
  const [state, setState] = useState({
    user: null,
    loaded: false,
  });

  const signOut = () => {
    return firebase.auth().signOut();
  }

  useEffect(() => {
    const _deleteAuthStateListener = firebase
      .auth()
      .onAuthStateChanged((user) => {
        setState({ user, loaded: true});
      });

    return _deleteAuthStateListener;
  }, []);

  return [state.loaded, state.user, signOut];
}
