import React, { useState } from 'react';
import Header from './components/header';
import RouterApp from './router/routerApp';
import LoginModal from './components/LoginModal/LoginModal';
import withFirebase from './hoc/withFirebase';
import './App.css';


function App({firebase}) {
	const [titulo, setTitulo] = useState("");
	if(firebase.loaded){
		return (
			<div className="App">
				<Header titulo={titulo}/>
				<RouterApp user={firebase.user} setTitulo={setTitulo} titulo={titulo}/>
				<LoginModal user={firebase.user}/>
			</div>
		);
	}else{
		return null; // Agregar loader para slow conection
	}
}

export default withFirebase(App);
