import { useState } from 'react';
import { obtenerProyecto, saveFacturasProyecto } from './proyectosAccess';
import { obtenerOportunidad, saveFacturasOportunidad } from './oportunidadesAccess';
import listaEstados from '../../config/estados_oportunidad.json';
import InvalidForecastError from '../../Errors/InvalidForecastError';
import InvalidEstadoError from '../../Errors/InvalidEstadoError';
import { OPORTUNIDAD_GANADA } from '../../constants/estadosOportunidad';


export default function useDataAccess() {
  const [dataAccess, setDataAccess] = useState({
    oportunidad: null,
    proyecto: null,
    proyectoOportunidadGanada: null,
    fetchOportunidad,
    fetchProyecto,
    errorFetchOportunidad: false,
    errorDataFetchOportunidad: null,
    errorFetchProyecto: false,
    errorDataFetchProyecto: null,
    loadingFetchOportunidad: false,
    loadingFetchProyecto: false,
    saveFacturasOportunidad,
    saveFacturasProyecto
  });

  async function fetchOportunidad(id){
    setDataAccess({...dataAccess, loadingFetchOportunidad: true, errorFetchOportunidad: false});
    obtenerOportunidad(id)
    .then(oportunidad => {

      if(!oportunidad.forecast){
        throw new InvalidForecastError();
      }else if(oportunidad.numeroEstado >= OPORTUNIDAD_GANADA){
        setDataAccess(
          {
            ...dataAccess, 
            proyectoOportunidadGanada: oportunidad.proyectos[0],
            loadingFetchOportunidad: false,
            oportunidad: null,
            errorFetchOportunidad: true,
            errorDataFetchOportunidad: new InvalidEstadoError(listaEstados[oportunidad.numeroEstado].value),
          });
      }else{
        setDataAccess(
          {
            ...dataAccess,
            oportunidad,
            loadingFetchOportunidad: false,
            errorFetchOportunidad: false,
            errorDataFetchOportunidad: null
          }
        );
      }
    })
    .catch(error => {
      setDataAccess(
        {
          ...dataAccess,
          loadingFetchOportunidad: false,
          oportunidad: null,
          errorFetchOportunidad: true,
          errorDataFetchOportunidad: error
        }
      );
    });
  }

  async function fetchProyecto(id){
    setDataAccess({...dataAccess, loadingFetchProyecto: true, errorFetchProyecto: false});
    obtenerProyecto(id)
    .then(proyecto => {
      setDataAccess(
        {
          ...dataAccess,
          proyecto,
          loadingFetchProyecto: false,
          errorFetchProyecto: false,
          errorDataFetchProyecto: null
        }
      );
    })
    .catch(error => {
      setDataAccess(
        {
          ...dataAccess,
          loadingFetchProyecto: false,
          proyecto: null,
          errorFetchProyecto: true,
          errorDataFetchProyecto: error
        }
      );
    });
  }

  return dataAccess;
}
