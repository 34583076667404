import React from 'react';
import { Button, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme)=>({
    button:{
        background: '#d9261c',
        border: 0,
        borderRadius: 3,
        color: 'white',
        height: 38,
        padding: '0 15px',
        "&:hover":{
            background: '#b8291f',
        }
    }
}));

export default function VolverCRMButton({href, className}){

    const classes = useStyles();

    return (
        <Button 
            href={href}
            className={[classes.button, className]}
            variant="contained"
            color="primary">
                Volver al CRM
        </Button>
    );
}