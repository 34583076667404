import React from 'react';
import useDataAccess from '../hooks/DataAccess/useDataAccess';

export const DataAccessContext = React.createContext({});

export function DataAccessProvider({ children }) {
  const dataAccess = useDataAccess();

  return (
    <DataAccessContext.Provider value={dataAccess}>
      {children}
    </DataAccessContext.Provider>
  );
}