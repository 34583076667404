import React from 'react';
import ReactDOM from 'react-dom';
import firebase from 'firebase';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter, withRouter } from 'react-router-dom';
import firebaseConfig from './firebaseconfig';
import { FirebaseProvider } from './contexts/FirebaseContext';
import { DataAccessProvider } from './contexts/DataAccessContext';

const AppWithRouter = withRouter(App);
firebase.initializeApp(firebaseConfig);

ReactDOM.render(
      <FirebaseProvider>
        <DataAccessProvider>
          <BrowserRouter>
            <AppWithRouter />
          </BrowserRouter>
        </DataAccessProvider>
      </FirebaseProvider>,
  document.getElementById('root')
);

serviceWorker.unregister();
