import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import * as firebase from 'firebase';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
 
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function LoginModal(props) {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  
  const onClickLoginButton = () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    firebase
      .auth()
      .signInWithPopup(provider)
      .catch((err) => {
        console.log("Error iniciando sesion.. ", err);
      });
  }

    return (
        <div>
          <Modal
              open={props.user?false:true}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description">
            <div style={modalStyle} className={classes.paper}>
              <h2 id="simple-modal-title">Inicie sesión</h2>
              <p id="simple-modal-description">
                Por favor, inicie sesión para continuar.
              </p>
              <Button className={classes.botton}
                onClick={onClickLoginButton}
                variant="contained"
                color="secondary">
                Inicio de sesión
              </Button>
            </div>
          </Modal>
        </div>
        );
}
