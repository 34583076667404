const producionDevBool = (process.env.REACT_APP_PRODUCCION === "FALSE" )

//DEV
let firebaseConfig = {
    apiKey: "AIzaSyDQXD4KH9l30GHwrBFkFEAHwdsnBwlor2Y",
    authDomain: "viewcrm.firebaseapp.com",
    databaseURL: "https://viewcrm.firebaseio.com",
    projectId: "viewcrm",
    storageBucket: "viewcrm.appspot.com",
    messagingSenderId: "761352852297",
    appId: "1:761352852297:web:32a0c6b30732f71d718098",
  };

//Producción  
if(producionDevBool !== true){
  firebaseConfig = {
      apiKey: "AIzaSyBLAYUPvRhwpk5a60Ct0VJXFnvfcSR7l9A",
      authDomain: "crm-shortcuts.firebaseapp.com",
      databaseURL: "https://crm-shortcuts.firebaseio.com",
      projectId: "crm-shortcuts",
      storageBucket: "crm-shortcuts.appspot.com",
      messagingSenderId: "124870988968",
      appId: "1:124870988968:web:d4a5c851c04f9ecd0b1e9d",
    }
  }
export default firebaseConfig;