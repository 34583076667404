import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import './home.css';
import AnimatedLogo from './AnimatedLogo';

class Home extends Component {
    render() {
        return (
            <div className={'home-container'}>
                <div className={'background'}>
                    <AnimatedLogo />
                    <div className={'home-message-container'}>
                        <Typography variant="h4" gutterBottom>
                            Bienvenido al CRM helper Snoop!
                        </Typography>
                        <Typography variant="h6" gutterBottom>
                        Una página hecha por los chicos de sistemas.
                        </Typography>
                    </div>
                </div>
            </div>
        );
    }
}

export default (Home);