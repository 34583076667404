import React from 'react';
import useFirebase from '../hooks/useFirebase';

export const FirebaseContext = React.createContext({});

export function FirebaseProvider({ children }) {
  const [loaded, user, signOut] = useFirebase();

  return (
    <FirebaseContext.Provider value={{ loaded, user,signOut }}>
      {children}
    </FirebaseContext.Provider>
  );
}