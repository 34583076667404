import React from 'react';
import logo from './../../images/BOT.png';
import Typography from '@material-ui/core/Typography';
import { CRM_PROYECTO_DETAILVIEW } from '../../constants/url';
import VolverCRMButton from '../VolverCRMButton/VolverCRMButton';

export default function OportunidadGanada(props){ 
  
    function getLinkProyecto(){
        return CRM_PROYECTO_DETAILVIEW + props.proyecto.id;
    }

    

    return (
        <div className={'general-error-container'}>
            <div className={'general-error-background'}>
                <img src={logo} className="general-error-logo" alt="general-error-logo" />
                <Typography style={{marginTop: "3vh"}} variant="h4" gutterBottom>
                    {props.message}
                </Typography>

                <Typography style={{marginTop: "1.5vh", marginBottom: "2vh"}} variant="body2" gutterBottom>
                    {props.paragraph}
                </Typography>

                <Typography variant="body2" gutterBottom>
                    {"Proyecto asociado a la oportunidad ganada: "}
                    <a style={{color: "red"}} href={getLinkProyecto()} >{props.proyecto.nombre}</a>
                </Typography>
                
                <Typography style={{marginTop: "2vh"}} variant="subtitle2">
                Cualquier consulta comunicarse con Sistemas Internos.
                </Typography>
                <VolverCRMButton href={props.url} className="general-error-button-volver-crm" />
            </div>
        </div>
    );  
}