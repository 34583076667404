import React, { Fragment } from 'react';
import Home from '../containers/home';
import FacturasForecast from '../containers/FacturasOportunidad';
import Planificarfacturas from '../containers/FacturasProyecto';
import { Route, Switch } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { CssBaseline } from '@material-ui/core/';

const TITULO_FORECAST = "Planificación de facturas (Forecast)";
const TITULO_PLANIFICACION = "Planificación de facturas";

const RouterApp = (props, {location}) => {
    const currentKey = location? (location.pathname.split("/")[1] || "/"):"";
    const timeout = { enter: 10, exit: 500 };

    const renderForecast = (p) => {

        if(props.titulo !== TITULO_FORECAST){
            props.setTitulo(TITULO_FORECAST);
        }
        
        return props.user !== undefined && props.user !== null ? 
        <FacturasForecast {...p} email={props.user.email} />
        : null;
    }

    const renderPlanificacion = (p) => {

        if(props.titulo !== TITULO_PLANIFICACION){
            props.setTitulo(TITULO_PLANIFICACION);
        }
        
        return props.user !== undefined && props.user !== null ? 
        <Planificarfacturas {...p} email={props.user.email} />
        : null;
    }

    return (
        <Fragment>
            <CssBaseline />
            <div className="container-route">
                <TransitionGroup className="transition-group"> 
                    <CSSTransition
                        key={currentKey}
                        timeout={timeout}
                        classNames="fade"
                        appear
                    >
                        <Switch location={location}>
                                <Route exact path={'/'} render={(props)=> <Home/>}/>
                                <Route 
                                    path={'/facturasforecast/'}
                                    render={renderForecast} />
                                <Route 
                                path={'/planificarfacturas/'} 
                                render={renderPlanificacion} />
                        </Switch>
                    </CSSTransition>
                </TransitionGroup>
            </div>
        </Fragment>
    );
};

export default RouterApp;