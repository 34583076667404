import React from "react";
import {Box} from '@material-ui/core';
import "./style.css";
// import getCRM from "../../obtencionDatos/getCRM";

function InfoOportunidad(props){

    const formatterPeso = new Intl.NumberFormat('es-CO', {
        style: 'currency',
        currency: 'COP',
        minimumFractionDigits: 0
      })

    return(
        <Box  className="container">
            <div className="tittle"><h2 style={{margin: 0}}>{props.oportunidad.nombre}</h2></div>
            <div className={"data"}>
                <div className={"caja1"}>
                    <ul>
                        <li><b>SBU: </b>{props.oportunidad.SBU} </li>
                        <li><b>Cliente: </b>{props.oportunidad.cliente} </li>
                    </ul>
                </div>
                <div className={"caja2"}>
                    <ul>
                        <li><b>Fecha Cierre: </b>{props.oportunidad.fechaFinPlanificada}</li>
                        <li><b>Duración: </b>{props.oportunidad.mesesDuracion} meses</li>
                    </ul>
                </div>
                <div className={"caja3"}>
                    <ul>
                        <li><b>Monto total: </b>{formatterPeso.format(props.oportunidad.montoTotal)} </li>
                    </ul>
                    
                </div>
            </div>
        </Box>
    );
}

export default InfoOportunidad;