import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import currenciesProyectos from '../../config/currencies_proyectos.json';
import currenciesOportunidades from '../../config/currencies_oportunidades.json';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiInputBase-root': {
      margin: theme.spacing(1),
      width: '25ch',
      height: 20,
    },
  },
}));

function InputTextcurrency(props) {
  const classes = useStyles();
  
  const handleChange = (event) => {
    props.onchange(event, props.id);
  };
  
  let currencies = props.type === "PROYECTO" ? 
    currenciesProyectos:
    currenciesOportunidades;
  if(currenciesProyectos.some(c => c.key === props.currency)){
    currencies = currenciesProyectos;
  }else if(currenciesOportunidades.some(c => c.key === props.currency)){
    currencies = currenciesOportunidades;
  }



  return (
    <form className={classes.root} noValidate autoComplete="off">
      <div>
        <TextField
          id="outlined-select-currency"
          select
          value={props.currency}
          onChange={handleChange}
          variant="outlined"
        >
          {currencies.map((option) => (
            <MenuItem key={option.key} value={option.key}>
              {option.value}
            </MenuItem>
          ))}
        </TextField>
      </div>
    </form>
  );
}

export default  InputTextcurrency;