import moment from 'moment';
import { GET_PROYECTO_URL, URL_CREATE_FACT} from '../../constants/url';

const createGetProyectoUrl = id => GET_PROYECTO_URL + "&id=" + id;

function ordenarFacturas(f) {
    return f.sort((a, b) => new Date(a.fecha) - new Date(b.fecha));
}

export async function obtenerProyecto(id) {

    const response = await fetch(createGetProyectoUrl(id));
    if (response.ok) {
        const respJson = await response.json();
        if (Array.isArray(respJson.facturas) && respJson.facturas.length > 0) {
            respJson.facturas.forEach(f => {
                f.preCargada = true;
                f.fecha = moment(f.fecha).format("YYYY-MM-DD");
            });
        }

        return {
            id: respJson.id,
            cliente: respJson.cliente,
            clienteFinal: respJson.clienteFinal,
            SBU: respJson.sbu,
            nombre: respJson.nombre,
            fechaInicio: moment(respJson.fecha_de_inicio).format('YYYY-MM-DD'),
            fechaFinPlanificada: moment(respJson.fecha_fin_planificada).format('YYYY-MM-DD'),
            mesesDuracion: moment(respJson.fecha_fin_planificada).diff(respJson.fecha_de_inicio, 'months'),
            montoTotal: respJson.monto_total,
            currency: respJson.currency_id === 'dollars' ? '-99' : respJson.currency_id,
            lob: respJson.lob_principal,
            facturas: ordenarFacturas(respJson.facturas),
            servicio: respJson.servicio
        };
    } else {
        throw new Error("Error obteniendo el proyecto con el id " + id);
    }
}


export async function saveFacturasProyecto(facturas, id ,email) {

    facturas = facturas.map(factura => ({
        "id": factura.preCargada ? factura.id: null,
        "fecha": factura.fecha,
        "diasDiferencia": factura.diasDiferencias,
        "moneda": factura.moneda,
        "monto": Number(factura.monto),
        "lob": factura.lob,
        "rubro": factura.rubro,
        "deleted": factura.deleted === true ? true : false
    }));
    const body = {
        "facturas": facturas,
        "info": {
            "idRegistro": id,
            "email": email
        }
    }
    const config = {

        method: 'POST',
        body: JSON.stringify(body),
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    }
    console.log(body);
    const response = await fetch(URL_CREATE_FACT + "&ak839y=VvAQ7TsyuNp3JajZ6Rkr", config);
    if(!response.ok){
        throw new Error("Error guardando facturas, vuelva a intentarlo mas tarde.");
    }
    return response.status;
}