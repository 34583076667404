import React, { Component } from 'react';
import logo from './../../images/BOT.png';
import Typography from '@material-ui/core/Typography';
import VolverCRMButton from '../VolverCRMButton/VolverCRMButton';
import './generalError.css';

class GeneralErrorMessage extends Component {

    

    render() {
        return (
            <div className={'general-error-container'}>
                <div className={'general-error-background'}>
                    <img src={logo} className="general-error-logo" alt="general-error-logo" />
                    <Typography style={{marginTop: "3vh"}} variant="h4" gutterBottom>
                        {this.props.message}
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                        {this.props.description}
                    </Typography>
                    <Typography style={{marginTop: "2vh"}} variant="subtitle2">
                        Cualquier consulta comunicarse con Sistemas Internos.
                    </Typography>
                    <VolverCRMButton href={this.props.url} className={"general-error-button-volver-crm"} />
                </div>
            </div>
        );
    }
}

export default (GeneralErrorMessage);