import React from "react";
import { TextField} from "@material-ui/core";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import HelpIcon from '@material-ui/icons/Help';
import PropTypes from 'prop-types';
import SelectOption from "../SelectOption";
import "./styleModi.css";

const MyTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.black,
    color: 'white',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
  arrow: {
    color: "black"
  },
  popper: {
      overflow: 'initial'
  }
}))(Tooltip);



const useStyles = makeStyles(()=>({
    button:{
        background: '#d9261c',
        border: 0,
        borderRadius: 3,
        boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
        color: 'white',
        height: 42,
        padding: '0 25px',
        marginBottom:20,
    '&:hover':{
            background:'red',
        }
    },
    root:{
        '& .MuiInputBase-root': {
            width: '25ch',
            margin:0,
            marginTop:"2px",
            height: 30,
            paddingTop:0,
            background: "white"
        }
    },
    rootDia:{
        '& .MuiInputBase-root': {
            width: '65px',
            marginTop:1,
            height: 30,
            paddingTop:0,
            marginLeft: "4px",
            background: "white"
        }
    }
}));


function Modificacion(props){
    const ModificarFechaBase = (event) => {
        props.onFechaBaseChange(event.target.value);
    }

    const onDiffValueChange = (e) => {
        let val = e.target.value;
        if(val === "") val = 0;
        props.onDiffFacturasChange({value: Number(val), name: props.diffFacturas.name});
    }

    const onDiffNameChange = (e) => {
        props.onDiffFacturasChange({value: props.diffFacturas.value, name: e.target.value});
    }

    const classes = useStyles();

    console.log("FECHA",props.fechaBase);
return(
    <div style={{display: "flex", justifyContent: "center"}}>
    <div className={"containerPaper"}>
        <div className={"ModchildreOne"}>
            <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                <div className={"tituloMod"}>Fecha Base</div>
                <MyTooltip arrow title="Fecha de emisión de la primera factura">
                    <HelpIcon style={{fontSize: '16', marginLeft: "7px"}} />
                </MyTooltip>
            </div>
            <TextField 
                className={classes.root}
                variant="outlined" 
                onChange={ModificarFechaBase} 
                value={props.fechaBase}
                format="dd/MM/yyyy" 
                type="date">
                {props.fechaBase}
            </TextField>
        </div>
        <div className={"ModchildreTwo"}>
            <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                <div className={"tituloMod"}>Fecha de emisión de facturas</div>
                <MyTooltip arrow title="Tiempo entre facturas">
                    <HelpIcon style={{fontSize: '16', marginLeft: "7px"}} />
                </MyTooltip>
            </div>
            <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                <SelectOption style={{background: "white"}} fechaOption={props.diffFacturas.name} onchangeFechaOption={onDiffNameChange}/>
                <TextField required onChange={onDiffValueChange} className={classes.rootDia} value={props.diffFacturas.value} variant="outlined"/>
            </div>
        </div>
    </div>
    </div>
);
}

Modificacion.propTypes = {
    onDiffFacturasChange: PropTypes.func.isRequired,
    fechaBase: PropTypes.string.isRequired,
    diffFacturas: PropTypes.object.isRequired,
    onFechaBaseChange: PropTypes.func.isRequired
};



export default Modificacion;
