import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles((theme) => ({
  root: {
    '& .MuiInputBase-root': {
      margin: theme.spacing(1),
      maxWidth: '180px',
      height: 20,
    }
  },
  option : {
    maxWidth: "500px",
    maxHeight: "700px",
    minWidth: "150px"
  }
})); 

export default function Selector(props) {

  const classes = useStyle();

  const onChangeOption = (e) => {
    props.onChange(props.options.find(o => o.key === e.target.value));
  }

  return (
    <form className={classes.root} noValidate autoComplete="off">
      <div>
        <TextField
          className={classes.option}
          id="outlined-select-currency"
          select
          value={props.selected}
          onChange={onChangeOption}
          variant={props.variant}>
          {
            Array.isArray(props.options) ? 
            (
              props.options.map( o => (
                <MenuItem className={classes.option} key={o.key} value={o.key}>{o.value}</MenuItem>
              ))
            ):
            (
              null
            )
          }
        </TextField>
      </div>
    </form>
  );
}

Selector.propTypes = {
  options : PropTypes.arrayOf(),
  selected: PropTypes.any,
  onChange: PropTypes.func.isRequired
}
