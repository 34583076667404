import React from "react";
import logo from '../../images/logo.png';
import Typography from '@material-ui/core/Typography';
import "./style.css";

 export function Loading(){

    return(
        <div className={'home-container'}>
        <div className={'background'}>
            <img src={logo} className="App-logo" alt="logo" />
            <Typography className="loading" variant="h5" gutterBottom>
                Obteniendo datos del CRM...
            </Typography>
        </div>
        </div>);
    
}