import React from 'react';
import Tabla from './../../components/table/Table';
import GeneralErrorMessage from '../../components/GeneralErrorMessage/GeneralErrorMessage';
import { Loading } from './../../components/loading/index';
import InfoProyecto from '../../components/info/InfoProyecto';
import Modificacion from '../../components/Modificacion/Modificacion';
import moment from 'moment';
import { createRedirectUrl } from '../../Utils/index';
import './planificarFacturas.css';
import withDataAccess from '../../hoc/withDataAccess';
import ConfirmModal from '../../components/Modals/ConfirmModal';

class PlanificarFacturasProyecto extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            proyecto: null,
            notFound: false,
            loading: true,
            fechaBase: null,
            diffFacturas: {
                value: 1,
                name: 'month'
            },
            showModal: false,
            modalInfo: {
                title: 'Planificar Facturas',
                message: '',
                status: ''
            },
            tableButtonEnabled: true  
        }

        this.id = window.location.pathname.split("/")[2] || null;
    }

    componentDidMount(){
        this.props.dataAccess.fetchProyecto(this.id);
    }

    onFechaBaseChange(fecha){
        this.setState({...this.state, fechaBase: fecha});
    }

    onDiffFacturasChange(diff){
        this.setState({...this.state, diffFacturas: diff});
    }

    onCreateFacturas(filasFacturas){
        this.setState(oldState => ({...oldState,tableButtonEnabled: false }));
        this.props.dataAccess
        .saveFacturasProyecto(filasFacturas,this.props.dataAccess.proyecto.id,this.props.email)
        .then(data => this.showSuccessModal())
        .catch(err => this.showErrorModal());
    }

    showSuccessModal(){
        this.setState(oldState => ({
            ...oldState,
            showModal: true,
            modalInfo: {
                title: oldState.modalInfo.title,
                message: 'Se crearon correctamente la facturas, presione aceptar para volver al CRM.',
                status: 'success'
            }
        }));
    }

    showErrorModal(){
        this.setState(oldState => ({
            ...oldState,
            showModal: true,
            modalInfo: {
                title: oldState.modalInfo.title,
                message: 'Ocurrió un error vuelva a intertar',
                status: 'error'
            }
        }));
    }

    closeModal(){
        this.setState(oldState => ({
            ...oldState,
            showModal: false,
            tableButtonEnabled: true
        }));
    }

    onConfirm(){
        if(this.state.modalInfo.status === "success"){
            window.location.replace(createRedirectUrl(this.props.dataAccess.proyecto.id,'Snoop_ProyectosSnoop'));
        }else{
            this.closeModal();
        }
    }

    onCancel(){
        this.closeModal();
    }

    hayFacturasPrecargadas(){
        return this.props.dataAccess.proyecto &&
         Array.isArray(this.props.dataAccess.proyecto.facturas) && 
         this.props.dataAccess.proyecto.facturas.length > 0;
    }

    getFechaBase(){
        if(this.state.fechaBase !== null){
            return this.state.fechaBase;
        }else if(this.hayFacturasPrecargadas()){
            return this.props.dataAccess.proyecto.facturas[0].fecha;
        }else{
            return moment().format("YYYY-MM-DD");
        }
    }

    render(){
        const fechaBase = this.getFechaBase();
        return(
        <div className={'planificar-container'}>
            {
                this.props.dataAccess.loadingFetchProyecto ?
                <Loading />
                : this.props.dataAccess.errorFetchProyecto || this.props.dataAccess.proyecto === null ?
                <GeneralErrorMessage message={'No se pudo encontrar el proyecto o el id es incorrecto'} />
                :
                <>
                    <div className={'planificar-info-container'}>
                        <InfoProyecto proyecto={this.props.dataAccess.proyecto} />
                        <Modificacion 
                            onFechaBaseChange={this.onFechaBaseChange.bind(this)}
                            fechaBase={fechaBase}
                            onDiffFacturasChange={this.onDiffFacturasChange.bind(this)}
                            diffFacturas={this.state.diffFacturas}
                        />
                    </div>
                    <Tabla 
                        montoTotal={this.props.dataAccess.proyecto.montoTotal}
                        nMesesDuracion={this.props.dataAccess.proyecto.mesesDuracion}
                        fechaFinPlanificada={this.props.dataAccess.proyecto.fechaFinPlanificada}
                        fechaBase={fechaBase}
                        defaultCurrency={this.props.dataAccess.proyecto.currency}
                        diffFacturas={this.state.diffFacturas}
                        onCreateFacturas={this.onCreateFacturas.bind(this)}
                        lob={'servicios'}
                        buttonEnabled={this.state.tableButtonEnabled}
                        facturasIniciales={this.props.dataAccess.proyecto.facturas}
                        facturasPreCargadas={this.props.dataAccess.proyecto.facturas.lenght > 0}
                        type={"PROYECTO"}
                     />
                     <ConfirmModal
                     show={this.state.showModal}
                     title={this.state.modalInfo.title}
                     message={this.state.modalInfo.message}
                     status={this.state.modalInfo.status}
                     onConfirm={this.onConfirm.bind(this)}
                     onCancel={this.onCancel.bind(this)} />
                </>
            }
        </div>);
    }
}

export default withDataAccess(PlanificarFacturasProyecto);