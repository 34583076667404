import React,{ useState, useEffect } from 'react';
import shortid from 'shortid';
import { TableContainer, Table, TableHead, TableCell, TableRow, TableBody, TextField, Paper, Box } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import {Autorenew} from '@material-ui/icons';
import AddCircleOutline from '@material-ui/icons/AddCircleOutline'
import { createMuiTheme,ThemeProvider } from '@material-ui/core/styles';
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Moneda from "./../../components/InputTextcurrency";
import * as moment from 'moment';
import CurrencyHeader from '../currencyHeader';
import IconButton from '@material-ui/core/IconButton';
import Selector from './../../components/Selector/Selector';
import SelectorHeader from './../../components/Selector/SelectorHeader';
import rubros_subrubros from '../../config/rubro_subrubro_list.json';
import lobs from '../../config/lobs.json';
import { useWindowSize } from '../../hooks/windowSize';
import Button from '@material-ui/core/Button';
import NoteIcon from '@material-ui/icons/Note';
import Tooltip from '@material-ui/core/Tooltip';

const MyTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.black,
    color: 'white',
    boxShadow: theme.shadows[1],
    fontSize: 11,
    overflow: 'inherit'
  },
  arrow: {
    color: "black"
  },
  popper: {
      overflow: 'initial'
  }
}))(Tooltip);


const parseFacturas = (facturas) => {
    if(Array.isArray(facturas) && facturas.length > 0){
        let fechaInicial = moment(facturas[0].fecha);
        facturas.forEach((f, i) => {
            f.fecha = moment(f.fecha).format("YYYY-MM-DD");
            f.diasDiferencias = moment(f.fecha).diff(fechaInicial, 'days', false);
            f.diasDiferenciasAnterior = i > 0 ? facturas[i - 1].diasDiferencias : f.diasDiferencias; 
            f.monto = Number(f.monto).toFixed(2);
            f.currency = f.moneda;
        });
        console.log(facturas);
        return facturas;
    }else{
        return [];
    }
}

function Tabla(
    {
        montoTotal,
        fechaBase,
        fechaFinPlanificada, 
        nMesesDuracion, 
        defaultCurrency, 
        diffFacturas,
        onCreateFacturas,
        lob, 
        buttonEnabled, 
        facturasIniciales, 
        facturasPreCargadas,
        type
    }
    ){
    
    const [currency, setCurrency] = useState(defaultCurrency);
    const [mesesDuracion, setMesesDuracion] = useState(nMesesDuracion);
    const [rubroGeneral, setRubroGeneral] = useState(null);
    const [lobGeneral, setLobGeneral] = useState(lob);
    const [filas, setFilas] = useState(parseFacturas(facturasIniciales));
    const [windowWidth] = useWindowSize();
    const [openTooltip, setOpenTooltip] = useState(false);
    const [facturasEliminadas, setFacturasEliminadas] = useState([ ]);
    const [firstRender, setFirstRender] = useState(true);

    useEffect(() => {
        if(facturasPreCargadas === false){
            InicializarTabla(
            fechaBase,
            currency,
            montoTotal,
            mesesDuracion,
            0,
            diffFacturas.value,
            diffFacturas.name
            );
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [montoTotal]);

    useEffect(() => {
        onFechaBaseChange(fechaBase);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[fechaBase]);

    useEffect(() => {
        if(firstRender && facturasIniciales.length > 0) return setFirstRender(false);
        onDiffFacturasChange(diffFacturas.value,diffFacturas.name);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[diffFacturas]);

    useEffect(() => {
        if(filas.length > 1 && facturasIniciales.length === 0){
            let filasNuevas = JSON.parse(JSON.stringify(filas));
            filasNuevas.forEach( f => f.moneda = currency);
            setFilas(filasNuevas);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[ currency ]);

    function InicializarTabla(fechaInicial, currencyInicial, amountInicial, duracionInicial, diasBase = 0, cant = 1, DM = 'month'){

        if(DM === 'semana'){
            DM = 'days';
            cant = cant * 7;
        }

        fechaInicial = moment(fechaInicial).format("YYYY-MM-DD");

        if(duracionInicial === 0 && filas.length === 0){
            agregarFila();
        }else{
            const fechaBase = moment(fechaInicial);
            let filasNuevas=[];
            let fecha = fechaInicial;
            let dia = parseInt(diasBase,10);
            for(let i=0;i < duracionInicial; i++){
                let filaNueva = {
                    id: shortid.generate(),
                    preCargada: false,
                    fecha: fecha,
                    diasDiferencias: dia,
                    rubro: null,
                    lob: lob,
                    diasDiferenciasAnterior: dia,
                    moneda: currencyInicial,
                    monto:(amountInicial / duracionInicial).toFixed(2),
                    }
                    fecha = sumadorFechas(fecha,cant,DM);
                    dia = diasDeDiferencias(fecha,fechaBase);
                    filasNuevas.push(filaNueva);
                
            }
            setFilas(filasNuevas);
        }
    }

    function onFechaBaseChange(fb, cant, DM){

        if(DM === 'semana'){
            DM = 'days';
            cant = cant * 7;
        }

        let filasNuevas = JSON.parse(JSON.stringify(filas));

        if (Array.isArray(filasNuevas) && filasNuevas.length > 0){
            
            filasNuevas.forEach((f, i) => {
                f.fecha = sumadorFechas(fechaBase, f.diasDiferencias, 'days');
            });
            setFilas(filasNuevas);
        }
    }

    function onDiffFacturasChange( cant = 1, DM = 'month'){
        if(filas.length > 0){
            if(DM === 'semana'){
                DM = 'days';
                cant = cant * 7;
            }
            let filasNuevas = JSON.parse(JSON.stringify(filas));
            let fechaAux = sumadorFechas(filas[0].fecha,cant,DM);
            let diasDiferenciaAux = diasDeDiferencias(fechaAux, fechaBase); 
            filasNuevas.forEach((f, i) => {
                if(i === 0) return;
    
                f.fecha = fechaAux;
                f.diasDiferencias = diasDiferenciaAux;
                f.diasDiferenciasAnterior = filasNuevas[i - 1].diasDeDiferencias;
                fechaAux = sumadorFechas(fechaAux,cant,DM);
                diasDiferenciaAux = diasDeDiferencias(fechaAux, fechaBase); 
            });
            setFilas(filasNuevas);
        }
    }

    function sumadorFechas(fecha,cantidad,DM){
        let fechaModifUnix = moment(fecha).add(cantidad,DM); 
        let covertidoAFecha = moment(fechaModifUnix).format("YYYY-MM-DD");
        return covertidoAFecha;
    }

    function diasDeDiferencias(fecha1, fecha2){
        return (moment(fecha1).diff(fecha2,'days'));
    }

    const crearFilasTabla = (filas) => {

        if(filas.length < 1) return;

        return filas.map((fila, index) => (
            <TableRow className={classes.filas} key={fila.id}>
                <TableCell component="th" scope="row"><IconButton onClick={() => eliminarFila(fila.id)}><DeleteIcon /></IconButton></TableCell>
                <TableCell align="left">
                    <TextField className={classes.root}  variant="outlined" value={"$" + fila.monto} onChange={(e) => handleChangeMonto(e,fila.id)}/>
                </TableCell>
                <TableCell align="left">
                    <Selector style={{maxWidth: "80px"}} label={"Rubro"} variant="outlined" options={rubros_subrubros} selected={fila.rubro} onChange={e => onRubroChange(e,index)} />
                </TableCell>
                <TableCell align="left">
                    <Selector label={"lobs"} variant="outlined" options={lobs} selected={fila.lob} onChange={e => onLobChange(e,index)} />
                </TableCell> 
                <TableCell align="left">
                    <Moneda className={classes.root} type={type} currency={fila.moneda} onchange={handleChangeCurrency} id={fila.id}/>
                </TableCell>
                <TableCell className={classes.celdaInputDias} align="left">
                    <input style={{maxWidth: "120px", marginRight: "15px"}} value={fila.diasDiferencias} onChange={(e)=>handleChangeDiasEmision(e,fila.id)} type="number"/>
                </TableCell>
                <TableCell align="left">
                    <TextField className={classes.root}variant="outlined" onChange={(e) => handleDateChangeDate(e,fila.id)} value={fila.fecha} format="dd/MM/yyyy" type="date"/>
                </TableCell>
            </TableRow>
        ));
    }
    
    const onRubroChange = (option, index) => {
        let newLista = JSON.parse(JSON.stringify(filas));
        newLista[index].rubro = option.key;
        setFilas(newLista);
    }

    const setRubros = (r) => {
        let newLista = JSON.parse(JSON.stringify(filas));
        newLista.forEach(l => l.rubro = r);
        setFilas(newLista);
        setRubroGeneral(r);
    }

    const onLobChange = (option, index) => {
        let newLista = JSON.parse(JSON.stringify(filas));
        newLista[index].lob = option.key;
        setFilas(newLista);
    }

    const setLobs = (lob) => {
        let newLista = JSON.parse(JSON.stringify(filas));
        newLista.forEach(l => l.lob = lob);
        setFilas(newLista);
        setLobGeneral(lob);
    }

    const allChangedCurrency = (event)=>{
        let newLista = JSON.parse(JSON.stringify(filas));
        newLista.forEach( f => f["moneda"]=event.target.value );
        setCurrency(event.target.value);
        setFilas(newLista);
    }

    const handleChangeCurrency = (event,id)=>{
        let newDate = filas.find( element=>element.id === id );
        let index = filas.findIndex(element => element.id === newDate.id);
        let newLista = JSON.parse(JSON.stringify(filas));
        newDate["moneda"] = event.target.value;
        newLista.splice(index,1,newDate);
        setFilas(newLista);
    }
    const handleChangeMonto = (event,id)=>{
        let newDate = filas.find(element=>element.id === id);
        let index = filas.findIndex(element => element.id === newDate.id);
        let newLista = JSON.parse(JSON.stringify(filas));
        let newMonto = event.target.value.replace("$", "");
        newDate["monto"] = newMonto;
        newLista.splice(index,1,newDate);
        setFilas(newLista);
    }

    const handleDateChangeDate = (event,id) => {    
        let newDate = filas.find(element=>element.id === id);
        let index = filas.findIndex(element => element.id === newDate.id);
        let newLista = JSON.parse(JSON.stringify(filas));
        newDate["fecha"] = event.target.value;
        newDate["diasDiferencias"] = diasDeDiferencias(event.target.value, fechaBase ); 
        newDate["diasDiferenciasAnterior"]=diasDeDiferencias(event.target.value, fechaBase );
        newLista.splice(index,1,newDate);
        setFilas(newLista);
    }

    const handleChangeDiasEmision = (event,id) => {    
        if(event.target.value < 0) return ;
        let newData = filas.find(element => element.id === id);
        let index = filas.findIndex(element => element.id === newData.id);
        let newLista = JSON.parse(JSON.stringify(filas));
        newData["diasDiferencias"] = parseInt(event.target.value, 10); 
        newData["diasDiferenciasAnterior"] = newData["diasDiferencias"];
        newData["fecha"] =  sumadorFechas(fechaBase, newData["diasDiferencias"],'days');
        newLista.splice(index,1,newData);
        setFilas(newLista);
    }

    const handleChangetfila = () => {
        let newLista = JSON.parse(JSON.stringify(filas));
        let montoFinal = (montoTotal / (filas.length)).toFixed(2);
        console.log(montoFinal);
        newLista.forEach(f => f["monto"] = montoFinal);
        setFilas(newLista);
    }
    const agregarFila = () => {
        let filasNuevas = JSON.parse(JSON.stringify(filas));
        let filaNueva = {};
        if(filas.length > 0){
            filaNueva = filas[filas.length - 1];
            filaNueva.fecha = sumadorFechas(filaNueva.fecha,1,'month');
            filaNueva.diasDiferencias = diasDeDiferencias(filaNueva.fecha,filasNuevas[0].fecha);
        }else{
            filaNueva.lob = lob;
            filaNueva.monto = Number(montoTotal).toFixed(2);
            filaNueva.moneda = currency;
            if(nMesesDuracion === 0){
                filaNueva.diasDiferencias = diasDeDiferencias(fechaFinPlanificada, fechaBase);
                filaNueva.fecha = fechaFinPlanificada;
            }else{
                filaNueva.fecha = sumadorFechas(fechaBase, 1, 'month');
                filaNueva.diasDiferencias = diasDeDiferencias(filaNueva.fecha, fechaBase);
            }
        }
        filaNueva.id = shortid.generate();
        filasNuevas.push(filaNueva);
        setFilas(filasNuevas);
        setMesesDuracion(Number(mesesDuracion) + 1);
        showTooltip();
    }

    const showTooltip = () => {
        if(openTooltip === false){
            setOpenTooltip(true);
            setTimeout(() => setOpenTooltip(false), 5500);
        }
    }

    const eliminarFila  = (id) => {
        console.log(id);
        let filasNuevas = Array.from(filas.filter(f => f.id !== id));
        let filaEliminada = filas.find(f => f.id === id);
        if(filaEliminada.preCargada){
            filaEliminada.deleted = true;
            facturasEliminadas.push(filaEliminada);
            setFacturasEliminadas(Array.from(facturasEliminadas));
        }
        console.log("Filas nuevas",filasNuevas);
        setFilas(filasNuevas);
        setMesesDuracion(Number(mesesDuracion) - 1);
        showTooltip();
    }

    const onCrearFacturasClick = () => {
        let facturas = filas.concat(facturasEliminadas);
        facturas.forEach(f => {
            f.monto = f.monto === "" ? 0 : f.monto;
        });
        onCreateFacturas(facturas);
    }

    const verifyInputs = () => filas.every(f => f.rubro !== null);

    let tableWidth = "84vw";

    if(windowWidth < 1150){
        tableWidth = "96vw";
    }

    const classes = useStyles({tableWidth});
            return (
                <div style={{display: "flex", flex: 8, alignItems: "center", paddingBottom: "15px", flexDirection: "column"}}>
                    <Box className={classes.container}>
                        <ThemeProvider theme={theme}>
                            <TableContainer variant={'dense'} component={Paper} className={classes.MuiTableContainer}>
                                <Table size="small" className={classes.tabla} stickyHeader aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell align="left"></StyledTableCell>
                                            <StyledTableCell align="left">
                                                <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center"}}>
                                                    <p style={{marginRight: "7px"}}>{'Monto total'}</p>
                                                    <MyTooltip
                                                        open={openTooltip} 
                                                        title={'Distribuye el monto total equitativamente entre todas las facturas'}
                                                        placement={'top'}
                                                        arrow>
                                                        <Autorenew style={{cursor: "pointer"}} onClick={handleChangetfila}/>
                                                    </MyTooltip>
                                                </div>
                                            </StyledTableCell>
                                            <StyledTableCell align="left">
                                                <SelectorHeader label={"Rubro/subrubro"} options={rubros_subrubros} selected={rubroGeneral} onChange={r => setRubros(r.key)} />
                                            </StyledTableCell>
                                            <StyledTableCell align="left">
                                                <SelectorHeader options={lobs} selected={lobGeneral} onChange={r => setLobs(r.key)} />
                                            </StyledTableCell>
                                            <StyledTableCell align="left"><CurrencyHeader type={type} onchange={allChangedCurrency} currency={currency}/></StyledTableCell>
                                            <StyledTableCell align="left">Días de emisión</StyledTableCell>
                                            <StyledTableCell align="left">{'Fecha'}</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody className={classes.tableCell}>
                                            {
                                                crearFilasTabla(filas)
                                            }
                                        <TableRow>
                                            <TableCell><IconButton onClick={agregarFila}><AddCircleOutline /></IconButton></TableCell>
                                            <TableCell></TableCell>
                                            <TableCell></TableCell>
                                            <TableCell></TableCell>
                                            <TableCell></TableCell>
                                            <TableCell></TableCell>
                                            <TableCell></TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </ThemeProvider>
                    </Box>
                    <Button
                        onClick={onCrearFacturasClick}
                        variant="contained"
                        color="primary"
                        size="large"
                        disabled={!verifyInputs() || !buttonEnabled}
                        className={classes.button}
                        startIcon={<NoteIcon />}
                    >
                        Guardar Facturas
                    </Button>                     
                </div>
            );
}


const theme = createMuiTheme({

    overrides: {
        // Style sheet name
        MuiTable: {
          // Name of the rule
          root: {
            // Some CSS
            width: "100%",
          },
          
        },
        MuiTableRow:{
            root: { //for the body
                height: "10px"
            }
        },
        MuiTableCell:{
            padding: 0
        }
      },
    });

const StyledTableCell = withStyles((theme) => ({
    head: {
      padding: 0 ,  
      backgroundColor: '#d9261c',
      color: theme.palette.common.white,
      borderBottom: "0px"
    },
    body: {
      fontSize: 14
    },
  }))(TableCell);

const useStyles = makeStyles((theme)=>({
    table: {
        minWidth: 600
    },
    button:{
        background: '#d9261c',
        border: 0,
        borderRadius: 3,
        color: 'white',
        height: 42,
        padding: '0 25px',
        marginBottom:20,
        "&:hover":{
            background: '#b8291f',
        }
    },
    MuiTableContainer:props => ({
            marginBottom:20,
            overflow: "auto",
            width: "100%",
            "overflow-x": "auto",
        }),
    rooti:{
        '& .MuiInputBase-root': {
            height: 20
        },
    },

    tableCell:{
        '& .MuiTableCell-body': {
            padding:0,
            fontSize: 12
        }
    },

    margin:{
        marginTop:20,
        marginBottom:20,
    },
    celdaInputDias:{
        width: "10px"
    },
    tabla:{
        overflow: "scroll",
        "overflow-x": "auto",
        flex: 1,
        minHeight: "min-content",

    },
    filas:{
        height: "15px"
    },
    container:{
        flex: 7,
        display: "flex",
        width: "100%"

    },
    root:{
        width: "min-content",
        minWidth: "120px",
        height: "20px"
    }
}));

export default Tabla;
