import React from 'react';
import Tabla from './../../components/table/Table';
import GeneralErrorMessage from '../../components/GeneralErrorMessage/GeneralErrorMessage';
import { Loading } from './../../components/loading/index';
import InfoOportunidad from '../../components/info/InfoOportunidad';
import Modificacion from '../../components/Modificacion/Modificacion';
import moment from 'moment';
import ConfirmModal from '../../components/Modals/ConfirmModal';
import { createRedirectUrl } from '../../Utils/index';
import withDataAccess from '../../hoc/withDataAccess';
import './facturasForecast.css';
import ErrorOportunidad from '../../components/ErrorOportunidad/ErrorOportunidad';


class FacturasForecast extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            fechaBase: null,
            diffFacturas: {
                value: 1,
                name: 'month'
            },
            showModal: false,
            modalInfo: {
                title: 'Planificar Facturas (Forecast)',
                message: '',
                status: ''
            },
            tableButtonEnabled: true
        }
        this.id = window.location.pathname.split("/")[2] || null;
    }

    componentDidMount(){
        this.props.dataAccess.fetchOportunidad(this.id);
    }

    onFechaBaseChange(fecha){
        this.setState({...this.state, fechaBase: fecha});
    }

    onDiffFacturasChange(diff){
        this.setState({...this.state, diffFacturas: diff});
    }

    onCreateFacturas(filasFacturas){
        this.setState(oldState => ({...oldState,tableButtonEnabled: false }));
        this.props.dataAccess
        .saveFacturasOportunidad(filasFacturas,this.props.dataAccess.oportunidad.id, this.props.email)
        .then( data => this.showSuccessModal())
        .catch(err => this.showErrorModal());
    }

    
    
    showSuccessModal(){
        this.setState(oldState => ({
            ...oldState,
            showModal: true,
            modalInfo: {
                title: oldState.modalInfo.title,
                message: 'Se crearon correctamente la facturas, presione aceptar para volver al CRM.',
                status: 'success'
            }
        }));
    }

    showErrorModal(){
        this.setState(oldState => ({
            ...oldState,
            showModal: true,
            modalInfo: {
                title: oldState.modalInfo.title,
                message: 'Ocurrió un error vuelva a intertar',
                status: 'error'
            }
        }));
    }

    closeModal(){
        this.setState(oldState => ({
            ...oldState,
            showModal: false,
            tableButtonEnabled: true
        }));
    }

    getCRMUri(){
        return createRedirectUrl(this.id,'Opportunities');
    }

    onConfirm(){
        if(this.state.modalInfo.status === "success"){
            window.location.replace(this.getCRMUri());
        }
    }

    onCancel(){
        this.closeModal();
    }

    hayFacturasPrecargadas(){
            return this.props.dataAccess.oportunidad &&
             Array.isArray(this.props.dataAccess.oportunidad.facturas) && 
             this.props.dataAccess.oportunidad.facturas.length > 0;
    }

    getFechaBase(){
        if(this.state.fechaBase !== null){
            return this.state.fechaBase;
        }else if(this.hayFacturasPrecargadas()){
            return this.props.dataAccess.oportunidad.facturas[0].fecha;
        }else{
            return moment().format("YYYY-MM-DD");
        }
    }
    

    render(){
        const fechaBase = this.getFechaBase();
        return(
        <div className={'forecast-container'}>
            {
                
                this.props.dataAccess.loadingFetchOportunidad ?
                <Loading />
                : this.props.dataAccess.errorFetchOportunidad ?
                <ErrorOportunidad 
                    url={this.getCRMUri()}
                    errorData={this.props.dataAccess.errorDataFetchOportunidad}
                    proyecto={this.props.dataAccess.proyectoOportunidadGanada} />
                : this.props.dataAccess.oportunidad === null ? 
                <GeneralErrorMessage message={"No se pudo encontrar el proyecto o el id es incorrecto"} />
                :
                <>
                    <div className={'forecast-info-container'}>
                        <InfoOportunidad oportunidad={this.props.dataAccess.oportunidad} />
                        <Modificacion 
                            onFechaBaseChange={this.onFechaBaseChange.bind(this)}
                            fechaBase={fechaBase}
                            onDiffFacturasChange={this.onDiffFacturasChange.bind(this)}
                            diffFacturas={this.state.diffFacturas}
                        />
                    </div>
                    <Tabla
                        montoTotal={this.props.dataAccess.oportunidad.montoTotal}
                        nMesesDuracion={this.props.dataAccess.oportunidad.mesesDuracion}
                        fechaBase={fechaBase}
                        fechaFinPlanificada={this.props.dataAccess.oportunidad.fechaFinPlanificada}
                        defaultCurrency={this.props.dataAccess.oportunidad.currency}
                        diffFacturas={this.state.diffFacturas}
                        onCreateFacturas={this.onCreateFacturas.bind(this)}
                        lob={this.props.dataAccess.oportunidad.lob}
                        buttonEnabled={this.state.tableButtonEnabled}
                        facturasIniciales={this.props.dataAccess.oportunidad.facturas}
                        facturasPreCargadas={this.props.dataAccess.oportunidad.facturas.lenght > 0}
                        type={"OPORTUNIDAD"}
                     />
                     <ConfirmModal
                        show={this.state.showModal}
                        title={this.state.modalInfo.title}
                        message={this.state.modalInfo.message}
                        status={this.state.modalInfo.status}
                        onConfirm={this.onConfirm.bind(this)}
                        onCancel={this.onCancel.bind(this)}
                     />
                </>
            }
        </div>);
    }
}

export default withDataAccess(FacturasForecast);