import moment from 'moment';
import { GET_OPORTUNIDAD_URL, URL_CREATE_FACT_FORECAST } from '../../constants/url';


const createGetOportunidadUrl = id => GET_OPORTUNIDAD_URL + "&id=" + id;

function ordenarFacturas(f) {
    return f.sort((a, b) => new Date(a.fecha) - new Date(b.fecha));
}

export async function obtenerOportunidad(id) {

    const response = await fetch(createGetOportunidadUrl(id));
    if (response.ok) {
        const respJson = await response.json();
        if (Array.isArray(respJson.facturas) && respJson.facturas.length > 0) {
            respJson.facturas.forEach(f => {
                f.preCargada = true;
                f.fecha = moment(f.fecha).format("YYYY-MM-DD");
            });
        }
        const oportunidad = {
            id: respJson.id,
            cliente: respJson.cliente,
            clienteFinal: respJson.cliente_final,
            SBU: respJson.sbu,
            nombre: respJson.nombre,
            estado: respJson.estado,
            numeroEstado: Number(respJson.estado.split("_")[0]),
            forecast: respJson.forecast == 1 ? true : false,
            fechaFinPlanificada: moment(respJson.fecha_fin_planificada).format('YYYY-MM-DD'),
            montoTotal: respJson.monto_total,
            montoServicios: respJson.monto_servicios,
            montoLicencias: respJson.monto_licencias,
            currency: respJson.currency_id,
            mesesDuracion: respJson.meses_duracion,
            lob: respJson.lob_principal,
            facturas: ordenarFacturas(respJson.facturas),
            servicio: respJson.servicio,
            proyectos: respJson.proyectos
        };
        return oportunidad;
    } else {
        throw new Error("Error obteniendo la oportunidad con el id " + id);
    }
}

export async function saveFacturasOportunidad(facturas, id, email) {

    facturas = facturas.map(factura => ({
        id: factura.preCargada ? factura.id: null,
        fecha: factura.fecha,
        diasDiferencia: factura.diasDiferencias,
        moneda: factura.moneda,
        monto: Number(factura.monto),
        lob: factura.lob,
        rubro: factura.rubro,
        deleted: factura.deleted === true ? true : false
    }));

    const body = {
        facturas,
        info: {
            idRegistro: id,
            email
        }
    }

    const config = {

        method: 'POST',
        body: JSON.stringify(body),
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    }
    const response = await fetch(URL_CREATE_FACT_FORECAST + "&ak839y=VvAQ7TsyuNp3JajZ6Rkr", config);
    if(!response.ok){
        throw new Error("Error guardando facturas, vuelva a intentarlo mas tarde.");
    }
    return response.status;
}