import React from 'react';
import logo from '../../images/logo.png';

export default function AnimatedLogo(){
    return (
        <img 
        src={logo} 
        className="App-logo" 
        alt="logo" />
    );
}