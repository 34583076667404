import React from 'react';
import { AppBar, Toolbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import logoSnoop from '../../images/logo grey and white 150px.png';

const useStyles = makeStyles({
    appbar: {
        backgroundColor: '#8c1c1c'
    },
    toolbar: {
        display: 'flex',
        justifyContent: 'left',
    },
    logo:{
        marginRight:50,
        height: 34
    }
});

const Header = (props) => {
    
    const classes = useStyles();

    let titulo = "CRM Shortcuts";
    if(props.titulo !== null && props.titulo !== ""){
        titulo += " - " + props.titulo;
    }

    return(
        <AppBar position="static" className={ classes.appbar }>
            <Toolbar variant="dense" className={ classes.toolbar }>
                <img src={logoSnoop} className={classes.logo} alt={'Snoop'}></img> 
                <h3>{titulo}</h3>
            </Toolbar>
        </AppBar>
    )
}

export default Header;
