import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles((theme) => ({
    root: {
      '& .MuiInputBase-root': {
        fontSize:'13px',
        margin: theme.spacing(1),
        maxWidth: '180px',
        height: 20,
        color:'white',
      },
      '& .MuiTableCell-root': {
          borderBottom: "0px"
      }
    }, 
  option : {
    maxWidth: "500px",
    minWidth: "150px"
  },
  
  }));

export default function SelectorHeader(props) {

  const classes = useStyle();

  const onChangeOption = (e) => {
    props.onChange(props.options.find(o => o.key === e.target.value));
  }

  let styleAdded = props.selected === null ? { } : {display: "none"};

  return (
    <form className={classes.root} noValidate autoComplete="off">
      <div>
        {/* <InputLabel htmlFor="outlined-select-header">{props.label}</InputLabel> */}
        <TextField
          className={classes.option}
          id="outlined-select-header"
          label={props.label || ""}
          InputLabelProps={{style:{...styleAdded,top: "-15px", left: "7px", color: "white"}}}
          select
          value={props.selected}
          onChange={onChangeOption}
          variant={props.variant}>
          {
            Array.isArray(props.options) ? 
            (
              props.options.map( o => (
                <MenuItem className={classes.option} value={o.key}>{o.value}</MenuItem>
              ))
            ):
            (
              null
            )
          }
        </TextField>
      </div>
    </form>
  );
}

SelectorHeader.propTypes = {
  options : PropTypes.arrayOf(),
  selected: PropTypes.any,
  onChange: PropTypes.func.isRequired
}
