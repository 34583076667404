import React from 'react';
import GeneralErrorMessage from '../GeneralErrorMessage/GeneralErrorMessage';
import OportunidadGanada from '../OportunidadGanada/OportunidadGanada';

export default function ErrorOportunidad({errorData, proyecto, url}){

    if(errorData.type === "InvalidEstadoError" && errorData.estado === "Ganada"){
        return(
            <OportunidadGanada 
                url={url}
                message={errorData.message === 'La oportunidad esta "Ganada"' ? 'La oportunidad ya está ganada' : errorData.message} 
                paragraph={'No se pueden planificar facturas forecast, las facturas se deben planificar desde el proyecto.'}
                description={errorData.info} 
                proyecto={proyecto}
                />
        );
    }
    return(
        <>
            <GeneralErrorMessage 
                url={url}
                message={errorData.message} 
                description={errorData.info}/>
        </>
    )
}